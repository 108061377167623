import zulip from 'zulip-js';
import { ref } from 'vue';
import axios from 'axios';

const client: any = ref({});
const adminClient: any = ref({});
const domain = process.env.VUE_APP_ZULIP_HOST || "https://localhost.localdomain";
const new_client: any = ref({});

export const initZulip = async (email: string) => {
    //  Initialize user client
    let config = {
        username: email,
        password: `pw_${email.split("@")[0]}`,
        realm: domain,
        // insecure: true,
    }

    zulip(config).then((zulip_client: any) => {
        client.value = zulip_client;
    })
};

export const getMe = (email: string) => {
    if (!client.value) {
        initZulip(email);
    }

    if (client.value && client.value.users) {
        return client.value.users.me.getProfile();
    }
    return Promise.reject("Client is not initialized (getMe)");
}


export const sendMessage = async (type: string, to: string, topic: string, content: string) => {
    /**
     * Send message from logged user to a specific user
     */
    if (client.value) {
        const message = {
            type: type, // direct or channel
            to: [to], // must be a list of emails
            topic: topic,
            content: content
        };
        return client.value.messages.send(message);
    }

    return Promise.reject("Client is not initialized (sendMessage)");
};

export const getUser = (email: string) => {
    /**
     * Return the user data if it exists.
     */
    const url = `/users/${email}`;
    return client.value.callEndpoint({
        url: url,
        method: "GET"
    });
};

const getUserApiKey = async (email: string) => {
    /**
     * Return the user API Key with the credentials are right.
     */
    const BASE_URL = domain + '/api/v1/fetch_api_key';
    const password = `pw_${email.split("@")[0]}`;
    const username = email;

    try {
        const response = await axios.post(
            BASE_URL,
            new URLSearchParams({ username, password }), // URL-encoded form data
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded', // Required by Zulip API
                },
            }
        );
        if (response.data.result === "success") {
            return response.data;
        } else {
            return;
        }
    } catch (error: any) {
        return Promise.reject("Error fetching API Key: " + error.toString());
    }
};

export const createUser = (email: string, name: string) => {
    /**
     * Create a new user with a default password
     */
    if (email && name) {
        const request = {
            email: email,
            password: `pw_${email.split("@")[0]}`,
            full_name: name
        };

        return adminClient.value.users.create(request);
    }
    return Promise.reject("Email and name must be provided");
}

export const subscribeChannel = (name: string) => {
    /**
     * Subscribe user to a channel.
     * The channel will be created if it doesn't exist.
     */
    const streams = {
        subscriptions: JSON.stringify([{ "name": name }]),
    };
    return client.value.users.me.subscriptions.add(streams);
}

export const getSubscribedChannels = () => {
    /**
     * Return the list of subscribed channels of a user.
     */
    return client.value.streams.subscriptions.retrieve();
}

export const getMessages = () => {
    if (client.value.messages) {
        const req = {
            anchor: "newest",
            num_before: 200,
            num_after: 0,
        };

        return client.value.messages.retrieve(req);
    }

    return Promise.reject("Client is not initialized (getMessages)");

}

export const createChat = (from: string, to: string) => {
    let from_api_key = "";
    let to_id = null;
    let draft_response = null;
    let drafts = null;

    try {
        // Get SENDER api key
        getUserApiKey(from).then((response) => {
            if (response) {
                from_api_key = response.api_key;

                // Get RECEIVER (to) user id
                getUserApiKey(to).then((result) => {
                    if (result) {
                        to_id = result.user_id;

                        // Define the payload
                        drafts = [
                            {
                                type: 'private',
                                content: 'Hello',
                                to: [to_id],
                                topic: 'dm',
                                //   timestamp: 1595479019,
                            },
                        ];

                        // Create a draft message
                        draft_response = axios.post(
                            domain + "/api/v1/drafts",
                            new URLSearchParams({ drafts: JSON.stringify(drafts) }), // Convert payload to URL-encoded format
                            {
                                auth: {
                                    username: from,
                                    password: from_api_key,
                                },
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                },
                            }
                        );
                    }
                });
            }
        });


        return draft_response;
    } catch (error: any) {
        return Promise.reject(`Error creating chat: ${error.toString()}`);
    }
}

export const newChannel = (channel_name: string, visitor_name: string, users: any) => {
    return {
        "roomId": channel_name,
        "roomName": visitor_name,
        "avatar": "",
        "users": users,
        "unreadCount": 0,
        "type": "private"
    }
}

export const getChannelNameByID = (from: number, target_id: number) => {
    return `_${from}_${target_id}`;
}

// export const getUserIdByEmail = (email: string) => {
//     //  Initialize new client
//     let new_client: any = null;

//     let config = {
//         username: email,
//         password: `pw_${email.split("@")[0]}`,
//         realm: domain,
//     }

//     zulip(config).then((zulip_client: any) => {
//         new_client = zulip_client;
//     });

//     if (new_client && new_client.users) {
//         // console.log("new client", new_client)
//         return new_client.users.me.getProfile();
//     }
//     return Promise.reject("New Client is not initialized");
// }

export const getUserIdByEmail = async (email: string): Promise<any> => {

    let result = null;

    result = await new Promise<string>((resolve) => {
        //  Initialize new client
        let config = {
            username: email,
            password: `pw_${email.split("@")[0]}`,
            realm: domain,
        }

        zulip(config).then(async (zulip_client: any) => {
            new_client.value = zulip_client;
            if (new_client.value && new_client.value.users) {
                try {
                    // Fetch authenticated user's info
                    const response = await new_client.value.users.me.getProfile();
                    return setTimeout(() => resolve(response), 1000)
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        });
    });

    return result;
}

const fetchData = async (): Promise<string> => {
    const value = await new Promise<string>((resolve) =>
        setTimeout(() => resolve("Data fetched!"), 1000)
    );
    return value;
};




// export const getMessages = () => {
//     if (client.value.messages) {
//         const req = {
//             anchor: "newest",
//             num_before: 50,
//             num_after: 0
//         };

//         return client.value.messages.retrieve(req);
//     }

//     return Promise.reject("Client is not initialized");

// }
