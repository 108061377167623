import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=65f83916"
import script from "./Chat.vue?vue&type=script&setup=true&lang=ts"
export * from "./Chat.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./Chat.vue?vue&type=style&index=0&id=65f83916&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports