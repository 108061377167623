
import { MutationPayload } from 'vuex';
import Dropdown from '@/components/dropdown.vue';
import AddonCart from '@/partials/AddonCart.vue';
import { AvailableLanguages } from '@/translations';
import store from '@/store';
import { IModal } from '@/components';
import Modal from '@/components/modal.vue';
import { IDropdown } from '.';
import { computed, defineComponent } from 'vue';
import useContext from '@/composition/context';
import useAuth from '@/composition/auth';
import { getMyPermissions } from '@/api/exhibitors';
import router from '@/router';
const appDomain = process.env.VUE_APP_DOMAIN;

export default defineComponent({
  components: { Dropdown, AddonCart, Modal },
  props: {
    managing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropdown: {} as IDropdown,
      navbarActive: false,
      notificationsOpened: false,
      exhibitor: 0,
      exhibitorIsQRCodeOrNFCReader: false,
      event: 0,
      fixNavTimeout: 0,
      appDomain,
      modal: {
        isActive: false,
        isCard: true,
      } as IModal,
      nfcEnabled: false,
      nfcAvailable: false,
      tag: '',
      currentUserPermissions: [] as string[],
      isOnboarding: false,
    };
  },
  setup() {
    const {
      translations,
      language,
      eventLanguages,
      contextEvent,
      userIsAdmin,
      userManages,
      relatedVisitors,
      userExhibits,
      relatedExhibitors,
      userRolesSet,
      contextVisitor,
      userIsExhibitor,
      userIsVisitor,
      userExhibitor,
      userVisitor,
      userIsManager,
      eventContextRoute,
    } = useContext();

    const { currentUser, userIsLoggedIn } = useAuth();

    const user = computed(() => store.getters.currentUser);
    const relatedEvents = computed(() => store.getters.relatedEvents);
    const notifications = computed(() => store.getters.notificationsNr);
    const messages = computed(() => store.getters.messages);
    const cartAddons = computed(() => store.getters.cartAddons);
    const navbarOpened = computed(() => store.getters.navbarOpened);
    const navbarBottom = computed(() => store.getters.navbarBottom);
    const chatIsLogged = computed(() => store.getters.chatIsLogged);

    const userExhibitors = computed(
      () =>
        relatedExhibitors.value &&
        relatedExhibitors.value.filter((visitor) => visitor.event === contextEvent.value.id),
    );

    const visitorInitial = computed(() =>
      userIsAdmin.value && contextVisitor.value.id === 0
        ? 'A'
        : (contextVisitor.value.name && contextVisitor.value.name.charAt(0)) ||
          (currentUser.value.name && currentUser.value.name.charAt(0)),
    );

    return {
      eventContextRoute,
      userExhibitors,
      visitorInitial,
      user,
      messages,
      userIsExhibitor,
      userIsVisitor,
      userExhibitor,
      userVisitor,
      userIsManager,
      currentUser,
      notifications,
      translations,
      language,
      eventLanguages,
      contextEvent,
      userIsAdmin,
      userManages,
      relatedExhibitors,
      relatedVisitors,
      userExhibits,
      userRolesSet,
      relatedEvents,
      contextVisitor,
      userIsLoggedIn,
      cartAddons,
      navbarOpened,
      navbarBottom,
      chatIsLogged,
    };
  },
  mounted() {
    // if ('NDEFReader' in window) {
    //   this.nfcAvailable = true;
    //   store.commit('addPopup', {
    //     message: 'NFC available',
    //     type: 'success',
    //     autohide: true,
    //   });
    // }
  },
  async created() {
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    this.event = this.contextEvent.id;

    store.commit('loadCart');

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setContextExhibitor' && mutation.payload.id) {
        this.event = this.contextEvent.id;
        this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
        this.exhibitorIsQRCodeOrNFCReader = mutation.payload.qrcode_reader;
        if (this.userIsLoggedIn) {
          getMyPermissions({
            exhibitor: this.exhibitor,
          }).then((response) => {
            this.currentUserPermissions = response.data.results
              .filter((permission) => permission.value)
              .map((permission) => permission._permission.name);
          });
        }
      }
      if (mutation.type === 'setContextVisitor' && mutation.payload.id) {
        this.event = this.contextEvent.id;
      }
    });

    this.$router.afterEach(() => {
      this.navbarActive = false;
    });
  },
  methods: {
    toggleNavbar() {
      this.navbarActive = !this.navbarActive;
    },
    logout() {
      store.commit('setUserLogout');
      store.commit('setContextRole', 'unset');
      store.commit('setContextEvent', {});
      store.commit('setContextExhibitor', {});
      store.commit('setContextVisitor', {});
    },
    openNotifications() {
      this.notificationsOpened = true;
      store.commit('openNotifications');
    },

    openScanner() {
      this.modal.isActive = false;
      if (this.exhibitor) {
        this.$router.push(
          `${this.eventContextRoute}/manage-exhibitor/${this.exhibitor}/visitors/scanner`,
        );
      } else {
        store.commit('openScanner');
      }
    },

    openNFCReader() {
      this.modal.isActive = false;
      this.$router.push(
        `${this.eventContextRoute}/manage-exhibitor/${this.exhibitor}/visitors/nfc-reader`,
      );
    },

    openMessages() {
      store.commit('setIsSessionChannel', false);
      store.commit('openMessages', true);
      store.commit('loadFirstChannel');
    },

    openHelpCenter() {
      store.commit('openHelpCenter');
    },

    changeLanguage(lang: AvailableLanguages) {
      const localStorageLangProp = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
      localStorage.setItem(localStorageLangProp, lang);
      store.commit('setLanguage', lang);
      this.$forceUpdate();
    },
    openScanOptions() {
      this.modal.isActive = true;
    },
  },
  watch: {
    $route() {
      const mobileScanner = this.$refs['mobileScanner'];

      if (mobileScanner) {
        (mobileScanner as HTMLElement).style.transition = '';
        setTimeout(() => {
          (mobileScanner as HTMLElement).style.transition = 'bottom 800ms';
        }, 800);
      }

      this.isOnboarding = router.currentRoute.name
        ? ['VisitorOnboarding', 'OnboardingGuest', 'TicketStore'].includes(router.currentRoute.name)
        : false;
    },
  },
});
